<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">

        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdAt | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

        <!-- Actions -->
        <template #cell(actions)="data">
            <b-dropdown class="mx-1" right text="İşlemler" variant="flat-primary">
                <b-dropdown-item variant="flat-primary" @click="openDocument(data.item)">
                    <feather-icon icon="LinkIcon" class="mr-50" />
                    <span class="align-middle">Önizle</span>
                </b-dropdown-item>
                <b-dropdown-item variant="flat-primary" @click="editDocument(data.item)" v-if="data.item.originalRef=='00000000-0000-0000-0000-000000000000'">
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span class="align-middle">Düzenle</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteDocument(data.item.id)" v-if="data.item.originalRef=='00000000-0000-0000-0000-000000000000'">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span class="align-middle">Sil</span>
                </b-dropdown-item>
            </b-dropdown>
        </template>

    </b-table>

    <!--Open Add Video-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addDocumentPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Döküman Ekle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Döküman Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addDocumentRequest.title" />
                </b-form-group>
                <b-form-group label="Döküman Linki" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addDocumentRequest.externalUrl" />
                </b-form-group>
                <b-form-group label="Döküman" label-for="name">
                    <b-form-file accept="application/pdf" ref="file-input" v-model="addDocumentRequest.url" class="mb-2" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addDocument">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>
    <!--Open Edit Video-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editDocumentPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Döküman Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Video Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editDocumentRequest.title" />
                </b-form-group>
                <b-form-group label="Video Url" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editDocumentRequest.url" />
                </b-form-group>
                <b-form-group label="Yayınlama" label-for="from">
                    <b-form-checkbox v-model="editDocumentRequest.isActive">
                        Öğrencilere Yayınlansın
                    </b-form-checkbox>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateDocument">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BFormFile,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BDropdown,
        BDropdownItem
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'title', label: 'Başlık' },
                { key: 'url', label: 'Url' },
                { key: 'actions', label: 'Önizleme' },
            ],
            users: [],
            addDocumentRequest: {
                videoGroupId: 0,
                url: null,
                title: '',
                externalUrl:null
            },
            editDocumentRequest: {
                documentId: 0,
                url: '',
                title: '',
                isActive: false
            },
            addDocumentPanel: false,
            editDocumentPanel: false
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addDocumentPanel = true;
        });
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Teacher/Document/" + this.$route.params.videoGroupId);
            this.users = users.data.data;
        },
        openDocument(data) {
            var myWindow = window.open(data.url, "Önizleme", 'fullscreen="yes"');
            // myWindow.document.write(data.url);
        },
        editDocument(data) {
            this.editDocumentRequest = {
                documentId: data.id,
                title: data.title,
                url: data.url,
                isActive: data.isActive
            }
            this.editDocumentPanel = true;
        },
        addDocument() {
            var formData = new FormData();
            formData.append("videoGroupId", this.$route.params.videoGroupId);
            formData.append("file", this.addDocumentRequest.url);
            formData.append("externalUrl",this.addDocumentRequest.externalUrl);
            formData.append("title", this.addDocumentRequest.title);
            this.$http.post("Teacher/AddDocument", formData).then((data) => {
                    this.getData();
                    this.addDocumentPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Döküman Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        updateDocument() {
            var request = this.editDocumentRequest;
            this.$http.put("Teacher/UpdateDocument", request).then((data) => {
                    this.getData();
                    this.editDocumentPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Video Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        deleteDocument(data) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$http.delete("Teacher/DeleteDocument/" + data).then(() => {
                                this.getData();
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'İşlem',
                                        icon: 'BellIcon',
                                        text: "İşlem Başarılı Döküman Silindi",
                                        variant: 'success'
                                    },
                                });
                            })
                            .catch((error) => {
                                //console.log(error.response.data)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Hata',
                                        icon: 'BellIcon',
                                        text: error.response.data.error.message,
                                        variant: 'danger'
                                    },
                                });
                            });
                    }
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
